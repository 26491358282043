import store from './store'
import './plugins/element.js'
import Vue from "vue";
import router from "./router";
import ElementUI from 'element-ui';
import App from './App.vue';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);


Vue.config.productionTip = false
//配置axios
import axios from 'axios'

Vue.prototype.$http = axios.create({
    // 设置超时时间
    timeout: 30000,
    // 基础url，会在请求url中自动添加前置链接
    // baseURL: process.env.VUE_APP_BASE_API
    headers:{
        ACCESS_TOKEN:localStorage.getItem('token')
    }
})


//导入api.js
import api from "@/utils/api"
import common from "@/utils/common"
//导入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.$api = api
Vue.prototype.$common = common
//全局的事件总线
const bus = new Vue;
Vue.prototype.$bus = bus;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

