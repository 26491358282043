import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Login.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginAndRegisterPage.vue'),
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: 'OnlineDevice',
        name: 'OnlineDevice',
        component: () => import('../pages/OnlineDevice.vue')
      },
      {
        path: 'UserClass',
        name: 'UserClass',
        component: () => import('../pages/UserClass.vue')
      },
      {
        path: 'UserCenter',
        name: 'UserCenter',
        component: () => import('../components/UserCenter.vue')
      },
      {
        path: 'DataCenter',
        name: 'DataCenter',
        component: () => import('../pages/DateCenter.vue')
      },
      {
        path: 'UserMain',
        name: 'UserMain',
        component: () => import('../pages/UserMain.vue')
      }
    ]
  },

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path != "/login") {
    let tk = localStorage.getItem('token')
    if (tk === null) {
      next({ path: "/login" })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

