let baseUrl = "https://api.yishuouser.cn"
if (process.env.NODE_ENV === "development") {
    baseUrl = "https://api.yishuouser.cn"
} else {
    baseUrl = "https://api.yishuouser.cn"
}

//
module.exports = {
    //在这里写api的键值对
    //例子："getUser": `${baseUrl}/user/getUser`
    "getOnlineBindDevicesRate": `${baseUrl}/health-bracelet/device/device_statistics/online_bind_devices_number`,
    "getOnlineDevices": `${baseUrl}/health-bracelet/device/device_statistics/online_devices`,
    "getDistributions": `${baseUrl}/health-bracelet/account/user_statistics/distributions`,
    "getHighFrequencyAbnormalUsers": `${baseUrl}/health-bracelet/account/user_statistics/high_frequency_abnormal_users`,
    sendCode:`${baseUrl}/health-bracelet/account/onboarding/sms_codes`,
    register:`${baseUrl}/health-bracelet/account/onboarding/owner`,
    loginWithCodes:`${baseUrl}/health-bracelet/account/onboarding/login_with_sms_codes`,
    loginWithPassword:`${baseUrl}/health-bracelet/account/onboarding/login_with_password`,
    getOwnerUserInfo:`${baseUrl}/health-bracelet/account/personal_center/user_and_owner`,
    updateOwnerInfo:`${baseUrl}/health-bracelet/account/personal_center/owner`,
    updateUserInfo:`${baseUrl}/health-bracelet/account/personal_center/user`,
    getUserStatics:`${baseUrl}/health-bracelet/account/user_board/statics`,
    getExceptionLog:`${baseUrl}/health-bracelet/account/user_board/exception_logging`,
    getStaticsByUserId:`${baseUrl}/health-bracelet/account/user_board/get_statics_by_user_id`,
    getProvinces:`${baseUrl}/health-bracelet/account/personal_center/provinces`,
    // sendCode:`${baseUrl}/health-bracelet/account/onboarding/sms-codes`,
}