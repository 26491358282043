<template>
    <div>
        <div class="loginContainer">
            <el-row>
                <el-button class="titleFont button" @click="centerDialogVisible = true">新用户注册</el-button>
            </el-row>
            <el-row>
                <el-button @click="$emit('login-click','showLoginUser')" class=" titleFont button">登录</el-button>
            </el-row>
            <el-row>
                <el-button @click="$emit('login-click','showLoginAdmin')" class=" titleFont button">管理员入口</el-button>
            </el-row>
        </div>

        <el-dialog
                title="提示"
                :visible.sync="centerDialogVisible"
                width="30%"
                center>
            <span>{{ content }}</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "Register",
    data() {
        return {
            centerDialogVisible: false,
            content: "感谢您信任并使用复昕老年健康管家产品与服务，我们非常重视并保障您的各项权益!\n" +
                "根据最新的法律法规、监管政策要求，我们更新了相关协议。您可以通过《复昕老年健康管家网站使用协议》、《复昕老年健康管家网站隐私政策》、《复昕账号使用协议》、《复昕账号隐私政策》。查阅完整的内容，以帮助您充分理解我们处理您个人信息的规则以及您的各项权利和义务。\n" +
                "如果您是未满14周岁的未成年人,请您通知您的父母或其他监护人共同阅读上述协议，并在您使用我们的服务前，取得您的父母或其他监护人的同意。\n" +
                "如您同意上述协议，请点击“同意并继续”，开始接受我们的产品与服务。\n",
        }
    },
    methods: {
        confirm() {
            this.centerDialogVisible = false;
            this.$bus.$emit("login-click", "showRegister");
        },
    }
};
</script>

<style lang="less" scoped>
.loginContainer {

}

.button {
  border-radius: 15px;
  margin-bottom: 30px;
  width: 300px;
}


.titleFont {
  font-size: 30px;
}


</style>

