<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'app',
}
</script>

<style>
@import "~@/assets/globalCss.css";

#app {
  width: 100%;
  max-width: 1920px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>
