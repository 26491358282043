function isValidPhone(str) {
    var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    return myreg.test(str);
}

function isValidPassword(str) {
    // 判断字符串是否为 6-12 位数字和字母的组合，区分大小写
    const regExp = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/;
    return regExp.test(str);
}

const ExceptionType = {
    "BLOOD_PRESSURE": "血压异常",
    "BLOOD_OXYGEN_SATURATION": "血氧异常",
    "HEART_RATE": "心率异常",
}

const UserType = {
    "admin": "admin",
    "owner": "owner",
}

function convertCoordinates(lat, lon) {
    const convertedLon = convertCoordinate(lon);
    const convertedLat = convertCoordinate(lat);
    return [convertedLat, convertedLon]
    // 在控制台输出转换后的经纬度值
}

function convertCoordinate(coordinate) {
    let value = parseFloat(coordinate);
    const direction = coordinate.slice(-1);

    if (direction === "W" || direction === "S") {
        value *= -1;
    }

    return value;
}

function getDateFromNumber(milliseconds) {
    let date = new Date(milliseconds);
    let temp2 = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let temp3 = date.getSeconds().toString().padStart(2, '0');
    return `${temp2}:${minute}:${temp3}`;
}

module.exports = {
    isValidPhone: isValidPhone,
    isValidPassword: isValidPassword,
    ExceptionType: ExceptionType,
    UserType: UserType,
    convertCoordinates: convertCoordinates,
    getDateFromNumber: getDateFromNumber,
}